@import "../../vars";

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 10;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.AppModal {
  &__close-btn {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 3rem;
    width: 3rem;
    text-align: center;
    font-size: 1.5rem;
    cursor: pointer;
  }

  &__icon {
    height: 6rem;
    align-items: start;
    background-position: left center;
    margin-bottom: 1rem;
  }

  &__title {
    font-size: 1.875rem;
    margin-bottom: 0.75rem;
    color: $primary;
  }

  &__content {
    line-height: 1.4;
  }

  &__list-subtitle {
    color: $primary;
    font-size: 1rem;
    line-height: 1.4;
  }

  &__list-title {
    color: $primary;
    font-size: 0.875rem;
    line-height: 1.2;
    margin-bottom: 3rem;
  }

  &__list-item {
    margin-bottom: 1rem;
    line-height: 1.4;
    margin-left: 3rem;
    position: relative;
  }

  &__list-icon {
    &::before {
      position: absolute;
      left: -3rem;
      font-size: 1.75rem;
      top: -0.25rem;
    }
    &-mobile::before {
      content: '\1f4f2';
    }
    &-mask::before {
      content: '\1f637';
    }
    &-laptop::before {
      content: '\1f4bb';
    }
    &-mailbox::before {
      content: '\1f4ee';
    }
    &-link::before {
      content: '\1f517';
    }
    &-cloud::before {
      content: '\2601';
    }
    &-signal::before {
      content: '\1f4f6';
    }
    &-button::before {
      content: '\1f518';
    }
    &-star::before {
      content: '\2b50';
    }
    &-clip::before {
      content: '\1f4ce';
    }
    &-screen::before {
      content: '\1f3ac';
    }
  }
}
