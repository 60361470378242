
$fonts:
  'Satoshi-BlackItalic',
  'Satoshi-Black',
  'Satoshi-Bold',
  'Satoshi-BoldItalic',
  'Satoshi-Italic',
  'Satoshi-LightItalic',
  'Satoshi-Light',
  'Satoshi-MediumItalic',
  'Satoshi-Medium',
  'Satoshi-Regular',
  'Satoshi-VariableItalic',
  'Satoshi-Variable';

@each $font in $fonts {
  @font-face {
    font-family: $font;
    src: url('./fonts/' + $font + '.woff2');
  }
}

.text-bold {
  font-family: 'Satoshi-Bold', sans-serif;
}

.text-bolditalic {
  font-family: 'Satoshi-BoldItalic', sans-serif;
}

.text-light {
  font-family: 'Satoshi-Light', sans-serif;
}

.text-lightitalic {
  font-family: 'Satoshi-LightItalic', sans-serif;
}

.text-medium {
  font-family: 'Satoshi-Medium', sans-serif;
}

.text-mediumitalic {
  font-family: 'Satoshi-MediumItalic', sans-serif;
}

.text-regular {
  font-family: 'Satoshi-Regular', sans-serif;
}

.text-regularitalic {
  font-family: 'Satoshi-RegularItalic', sans-serif;
}

.text-semibold {
  font-family: 'Satoshi-SemiBold', sans-serif;
}

.text-semibolditalic {
  font-family: 'Satoshi-SemiBoldItalic', sans-serif;
}

.text-tilting-black {
  font-family: 'Satoshi-Black', sans-serif;
}

.text-tilting-bold {
  font-family: 'Satoshi-Bold', sans-serif;
}

.text-tilting-light {
  font-family: 'Satoshi-Light', sans-serif;
}

.text-tilting-regular {
  font-family: 'Satoshi-Regular', sans-serif;
}

.text-tilting-semibold {
  font-family: 'Satoshi-SemiBold', sans-serif;
}

