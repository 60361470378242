.swiper-slide {
  height: auto !important;
}

// Crap mobile fixes
.iPad,
.isMobile {
  .container-vertical {
    height: 90vh !important;
  }
}

.btn-debug {
  //display: flex;
  display: none;
  z-index: 9999;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  flex-direction: column-reverse;
  opacity: 0.5;

  &::after {
    content: 'DEBUG';
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    color: white;
    font-size: 0.8rem;
    font-weight: 700;
  }

  button {
    font-size: 1rem;
    background-color: white;
    margin-bottom: 0.5rem;
    border: 1px solid transparent;
    cursor: pointer;
  }
}

strong {
  font-weight: 700 !important;
}
