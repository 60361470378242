@import "vars";

// Shared among all "Game" screens!

.Game {
  background-color: $white;
  
  &__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__src-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $white;
    //margin: auto;
    flex: 1;
  }

  &__dest-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: $secondary;
    padding-top: 1.8rem;
    flex: 1.5;

    &--outer {
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;
    }

    &--coin {
      background-color: $primary;
      display: flex;
      align-items: center;
    }
  }

  &__container-inner {
    flex: 1;
  }
}
