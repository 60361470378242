//$color__yellow: #f7e767;
//$color__blue: #3c5c9e;
//$color__white: #fff;

$color__error: #e46363;
$color__correct: #a4d65e;

$inactive_opacity: 0.2;

$breakpoint_lg: 1300px;

$white: #fffef4;
$gray-100: $white;
$gray-200: rgba(0, 0, 0, 0.2) !default;
$gray-300: #c4c4c4;
$gray-400: rgba(227, 220, 186, 0.3);
$gray-800: rgba(171, 167, 146, 0.8);
//$gray-300: #dee2e6 !default;
//$gray-400: #ced4da !default;
//$gray-500: #adb5bd !default;
//$gray-600: #6c757d !default;
//$gray-700: #495057 !default;
//$gray-800: #343a40 !default;
//$gray-900: #212529 !default;
$black: #232223;

$primary: #06519c;
$primary_light: #6bafd7;
$secondary: #c5dbef;

$yellow: #ffc030;
$yellow_dark: #e5a200;
$green: #80d569;

$border-radius: 0;
