// Cards

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.icon-0 {
  background-image: url('./svg/cards/00.png');
}

.icon-1 {
  background-image: url('./svg/cards/01.png');
}

.icon-2 {
  background-image: url('./svg/cards/02.png');
}

.icon-3 {
  background-image: url('./svg/cards/03.png');
}

.icon-4 {
  background-image: url('./svg/cards/04.png');
}

.icon-5 {
  background-image: url('./svg/cards/05.png');
}

.icon-6 {
  background-image: url('./svg/cards/06.png');
}

.icon-7 {
  background-image: url('./svg/cards/07.png');
}

.icon-8 {
  background-image: url('./svg/cards/08.png');
}

.icon-9 {
  background-image: url('./svg/cards/09.png');
}

.icon-10 {
  background-image: url('./svg/cards/10.png');
}

.icon-11 {
  background-image: url('./svg/cards/11.png');
}